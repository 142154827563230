import React, { FC, useCallback } from 'react'

import {
  ErrorBoundary,
  ErrorBoundaryProps,
  captureException,
} from '@sentry/react'
import { useDispatch } from 'react-redux'

import { checkBundleUpdateAction } from 'actions/system/checkBundleUpdateAction'
import { WithChildren } from 'common/types'
import { internalServerErrorPath } from 'components/paths'
import { uninstallPwa } from 'functions/pwa/uninstallPwa'
import { replace } from 'functions/router'

export const ErrorBoundaryWithRedirect: FC<{ uid: string } & WithChildren> = ({
  uid,
  children,
}) => {
  const dispatch = useDispatch()

  const handlerError = useCallback<NonNullable<ErrorBoundaryProps['onError']>>(
    (error) => {
      /** Возможно дублирование лога, если что потом уберем */
      captureException(`Error boundary "${uid}": ${error}`)
      if (process.env.production) {
        dispatch(replace(internalServerErrorPath))
      } else {
        console.error('ErrorBoundaryWithRedirect prevented for dev', error)
      }
    },
    [dispatch, uid]
  )

  const handleBeforeCapture = useCallback(
    (_scope, error) => {
      console.error('Error boundary: before capture', uid)

      if (error.name === 'ChunkLoadError') {
        console.error('Error boundary: chunk load error', uid)

        /**
         * удаляем ПВА, т.к. вероятно нет места для ее установки
         * а текущую поломанную версию нельзя оставлять.
         *
         * https://youtrack.mamba.ru/issue/M-11681
         */
        uninstallPwa()
      } else {
        /**
         * Попробуем обновить PWA.
         */
        dispatch(checkBundleUpdateAction())
      }
    },
    [dispatch, uid]
  )

  return (
    <ErrorBoundary onError={handlerError} beforeCapture={handleBeforeCapture}>
      {children}
    </ErrorBoundary>
  )
}
