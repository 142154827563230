/**
 * @param locale https://formatjs.io/docs/polyfills
 *
 *
 * Для не корневых bundle нужны родительские библиотеки
 * поэтому для библиотек intl-relativetimeformat и intl-pluralrules
 * мы грузит родительские intl-getcanonicallocales и intl-locale
 */

export const polyfill = async (locale: string) => {
  const promises: Promise<unknown>[] = []

  if (!Boolean(String.prototype.replaceAll)) {
    promises.push(
      import(
        /* webpackChunkName: "polyfills" */ 'core-js/features/string/replace-all'
      )
    )
  }

  /**
   * Для Intl нужно сохранить последовательность.
   * https://formatjs.io/docs/polyfills/intl-pluralrules
   * https://formatjs.io/docs/polyfills/intl-relativetimeformat
   *
   */
  if (!Boolean(Intl.PluralRules) || !Boolean(Intl.RelativeTimeFormat)) {
    await Promise.all([
      import(
        /* webpackChunkName: "polyfills" */ '@formatjs/intl-getcanonicallocales/polyfill-force'
      ),
      import(
        /* webpackChunkName: "polyfills" */ '@formatjs/intl-locale/polyfill-force'
      ),
      import(
        /* webpackChunkName: "polyfills" */ '@formatjs/intl-pluralrules/polyfill-force'
      ),
      import(
        /* webpackChunkName: "polyfills" */ '@formatjs/intl-relativetimeformat/polyfill-force'
      ),
    ]).catch((error) => {
      throw new Error(`File ${error} not downloaded, Head Polyfills`)
    })

    await import(
      /* webpackChunkName: "polyfills" */ `@formatjs/intl-pluralrules/locale-data/${locale}`
    ).catch((error) => {
      console.error(`${error} - not load data for ${locale} pluralrules`)
    })

    await import(
      /* webpackChunkName: "polyfills" */ `@formatjs/intl-relativetimeformat/locale-data/${locale}`
    ).catch((error) => {
      console.error(`${error} - not load data for ${locale} relativetimeformat`)
    })
  }

  if (!('CSSStyleSheet' in window) || !('replace' in CSSStyleSheet.prototype)) {
    promises.push(
      import(
        /* webpackChunkName: "polyfills" */ 'construct-style-sheets-polyfill'
      )
    )
  }

  try {
    await Promise.all(promises)
  } catch (error) {
    console.error(`File ${error} not downloaded, Polyfills`)
  }
}
